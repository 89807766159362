.nav {
    position: fixed;
    z-index: 2;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu__toggle {
    cursor: pointer;
}

.nav__overlay {
    position: fixed;
    background-color: var(--body-color);
    z-index: -2;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 4em;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.hamBox {
    position: relative;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3 ease;
    padding: 2.4em;
}


.hamBoxOpen {
    background: var(--cursor-color);
}

.lineTop, .lineBottom, .lineMiddle {
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 30px;
    height: 5px;
    border-radius: 10px;
    background: var(--button-color);
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.lineTop {
    top: 2em;
}

.lineMiddle {
    top: 2.5em;
}

.lineBottom {
    bottom: 1.5em;
}

.lineTop.spin {
    top: 2.35em;
    transform: rotate(135deg);
}

.lineMiddle.spin {
    display: none;
}

.lineBottom.spin {
    top: 2.35em;
    transform: rotate(225deg);
    background: var(--button-color);
}

ul.nav__links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

ul.nav__links li {
    list-style:  none;
    position: relative;
    margin-bottom: var(--mb-2);
}

ul.nav__links li a {
    text-decoration: none;
    color: var(--title-color);
    font-size: 3em;
    position: relative;
    top: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

ul.nav__links li a:hover {
    color: var(--button-color);
}

.nav__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 2em;
    display: flex;
    justify-content: space-between;
}

.social__media ul {
    display: flex;
}

.social__media ul li {
    list-style: none;
}

.social__media ul li a {
    position: relative;
    bottom: -20px;
    text-decoration: none;
    padding-left: 2em;
    opacity: 0;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.social__media i {
    font-size: 2rem;
    color: var(--button-color);
}

.nav__item-wrapper::after {
    content: "";
    position: absolute;
    top: 120px;
    left: -10%;
    width: 120%;
    height: 100%;
    background: var(--body-color);
    margin: 0 auto;
}

@media screen and (max-width: 992px) {
    .nav__footer {
        justify-content: space-around;
    }

    .social__media ul li a {
        padding: 0 1em;
    }
}
