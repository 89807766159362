.skills__content {
    padding: 2rem 4rem;
  }
  
  .skills__box {
    display: flex;
    justify-content: center;
  }
  
  .skills__list {
    display: flex;
    flex-wrap: wrap; 
    list-style-type: none; 
    justify-content: center ;
    padding: 0;
  }
  
  .skills__item {
    width: 200px; 
    margin: 1rem; 
  }
  
  .skills__data {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .skills__name {
    margin-top: 0.5rem; 
  }
  
  .icone {
    background-color: var(--button-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 45px;
    font-size: 2rem;
  }

  [data-theme="dark"] .icone {
    color: var(--container-color);
  }
  
  .icone:hover {
    transform: translateY(-10px);
  }
  
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .skills__item {
        width: 150px;
      }
    }
    
    /* For medium devices */
    @media screen and (max-width: 768px) {
        .skills__item {
            width: 120px;
          }
    }
    @media screen and (max-width: 576px) {
        .skills__item {
            width: 100%; 
          }
    }
    
    /* For small devices */
    @media screen and (max-width: 350px) {

        .skills__name {
            font-size: var(--small-font-size);
        }

        .skills__item {
            width: 110px;
          }

    }
    