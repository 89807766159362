.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.work__item {
  cursor: pointer;
  text-transform: capitalize;
  padding: 0.25rem 0.75rem;
}

[data-theme="dark"] .work__item {
  color: white;
}

[data-theme="dark"] .active-work {
  color: var(--container-color);
}

.work__item:hover {
  background-color: var(--button-color);
  color: var(--container-color);
  transform: translateY(-10px);
}
.work__container {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.work__img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.work__card {
  position: relative;
}

.work__mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.3s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border-radius: 10px;
}

.work__mask i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  cursor: pointer;
  display: none;
}

.work__mask:hover {
  opacity: 1;
}

.work__button {
  background-color: var(--button-color);
  color: var(--container-color);
  transition: 0.3s;
  font-size: var(--small-font-size);
  padding: 0.5em 1em;
  margin-top: 1em;
}


.demo-github__button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
}

.active-work {
  background-color: var(--title-color);
  color: var(--container-color);
}

.work__title {
  font-size: var(--h3-font-size);
  transition: 0.3s;
  margin-bottom: var(--mb-5);
}

.work__title,
.work__mask p {
  color: white;
  margin: 0 auto;
  text-align: center;
}

.work__mask p {
  line-height: 1.6;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
}

.work_skills {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.work_skills li {
  background-color: var(--container-color);
  font-weight: 800;
  color: var(--button-color);
  padding: 0.25rem 0.75rem;
}

[data-theme="dark"] .work_skills li {
  color: white;
}

[data-theme="dark"] .work__button {
  background-color: white;
}

[data-theme="dark"] .work__button:hover {
  background-color: var(--button-color);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .work__container {
    gap: 1rem;
    grid-template-columns: repeat(2, auto);
  }

  .work__mask {
    padding: 0.5em;
  }

  .work__mask p {
    font-size: 16px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .work_skills {
    margin-top: 0.5em;
  }

  .work_skills li {
    padding: 0.15em 0.5em;
    margin: 0 0.25em;
    font-size: 16px;
  }


  .work__mask i {
    top: 0;
    display: block;
    font-size: 30px;
  }

  .close {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .work__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .close {
    display: none;
  }
}

/* For small devices */
@media screen and (max-width: 576px) {
  .work__mask .work__button {
    font-size: 10px;
    padding: 0.2em 0.4em;
  }
  .close {
    display: none;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .work__item {
    font-size: var(--small-font-size);
  }

  .work__filters {
    column-gap: 0.25rem;
  }

  .work__mask {
    padding: 0.5em;
  }

  .work__mask p {
    font-size: 12px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .work_skills {
    margin-top: 0.5em;
  }

  .work_skills li {
    padding: 0.15em 0.5em;
    margin: 0 0.25em;
    font-size: 12px;
  }

  .work__mask .work__button {
    padding: 0.25em 0.5em;
    font-size: 12px;
    margin: 0.25em 0;
  }

  .work__mask i {
    top: 0;
  }

  .close {
    display: none;
  }
}
