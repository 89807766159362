.home-container {
    row-gap: 7rem;
}
.home__content {
grid-template-columns: 116px repeat(1, 1fr);
padding: 5.5rem;
column-gap: 2rem;
align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.80rem;
    color: var(--button-color);
}

.home__social-icon i:hover {
    color: var(--title-color-dark);
    transform: translateY(-10px);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__hand {
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle {
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}


.home__scroll {
    margin-left: auto;
    margin-right: auto;
}

[data-theme="dark"] .home__scroll path.wheel {
    stroke: white !important;
}

.wheel {
    animation: scroll 2s ease infinite;
}


@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__hand {
        height: 26px;
        width: 26px;
    }

    .home__subtitle {
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .home__content {
        grid-template-columns: 0.5fr 3fr;
        padding: 3.5rem 0 0 0;
    }

    .home_data {
        grid-column: 1/3;
    }

    .home__scroll {
        display: none;
    }
   
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {

    .home__content {
        padding: 0;
    }

   .home__hand {
    width: 22px;
    height: 22px;
   }
  }
  