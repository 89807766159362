.contact__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__card {
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.contact__card a {
    color: var(--title-color)
}

.contact__card-icon {
    font-size: 2rem;
    margin-bottom: var(--mb-0-25);
    color: var(--button-color);
}
.contact__card:hover {
    color: var(--title-color-dark);
    transform: translateY(-10px);
}
.contact__form {
    width: 360px;
}

.contact__form-div {
    position: relative;
    height: 4rem;
    margin-bottom: var(--mb-2);
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid black;
    background: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: -1;
}

[data-theme="dark"] .contact__form-input {
    border: 2px solid white;
}

[data-theme="dark"] .contact__form-input , [data-theme="dark"] .contact__form-input::placeholder  {
    color: white;   
}


.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    padding: 0.25rem;
    z-index: 1;
    background-color: var(--body-color);
}


.contact__form-area {
    height: 11rem;
}

.contact__form-area textarea {
    resize: none;
}
.sent-animation {
    animation: sentAnimation 0.5s ease;
  }
  
  @keyframes sentAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
    

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__container {
        column-gap: 3rem;
    }

    }
    
    /* For medium devices */
    @media screen and (max-width: 768px) {
        .contact__container {
            grid-template-columns: 1fr;
            row-gap: 3rem;
        }
   
        .contact__info {
            justify-content: center;
        }

        .contact__form {
            margin: 0 auto;
        }
    }
    
    @media screen and (max-width: 576px) {
   
        .contact__info {
            grid-template-columns: 1fr;
        }

        .contact__form {
            width: 100%;
        }
    }